import React from 'react';
import PropTypes from 'prop-types';

const Bags = ({ height, width }) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            className="header-bags"
            viewBox="0 0 1280.000000 1190.000000"
            preserveAspectRatio="xMidYMid meet">
            <metadata>
            Created by potrace 1.15, written by Peter Selinger 2001-2017
            </metadata>
            <g transform="translate(0.000000,1190.000000) scale(0.100000,-0.100000)"
            fill="#000000" stroke="none">
            <path d="M8227 11889 c-51 -12 -122 -49 -187 -98 -30 -23 -62 -41 -72 -41 -35
            0 -89 -63 -139 -163 -91 -180 -173 -240 -329 -241 -111 0 -161 18 -296 106
            -281 182 -473 256 -643 246 -89 -6 -156 -35 -212 -95 -60 -63 -82 -116 -87
            -213 -4 -73 0 -97 23 -165 30 -92 95 -201 167 -283 123 -139 269 -347 348
            -496 29 -53 56 -98 61 -101 5 -3 8 -52 8 -109 -1 -142 13 -203 68 -284 35 -53
            42 -70 34 -82 -7 -8 -19 -27 -28 -41 -9 -15 -37 -39 -62 -55 -111 -70 -141
            -115 -141 -210 0 -56 -1 -57 -65 -125 -87 -93 -110 -171 -83 -289 2 -11 -28
            -18 -147 -32 -294 -34 -391 -48 -495 -72 -613 -140 -1286 -705 -1690 -1420
            -154 -272 -255 -513 -359 -862 -85 -285 -106 -397 -121 -631 -29 -449 73
            -1022 228 -1281 22 -37 48 -96 57 -132 16 -64 58 -141 95 -175 10 -9 47 -71
            80 -137 113 -222 198 -342 306 -434 31 -27 83 -83 114 -124 68 -88 183 -202
            295 -289 44 -35 83 -66 87 -70 4 -3 -26 -6 -66 -6 -63 0 -78 -4 -112 -27 -83
            -57 -144 -203 -144 -341 0 -45 -7 -118 -15 -162 -10 -49 -18 -164 -21 -299 -4
            -170 -8 -225 -20 -248 -11 -21 -17 -68 -19 -161 -3 -93 -9 -144 -20 -171 -12
            -29 -17 -75 -18 -185 -2 -133 0 -150 20 -187 24 -45 76 -95 126 -120 17 -9 57
            -34 87 -54 72 -49 152 -89 214 -107 79 -23 121 -49 251 -148 66 -51 174 -129
            240 -174 151 -103 256 -185 398 -315 62 -56 157 -141 212 -189 123 -108 184
            -180 231 -272 44 -86 85 -141 135 -180 21 -16 50 -47 64 -68 32 -46 80 -77
            121 -77 33 0 150 37 205 65 19 10 48 30 64 44 19 16 69 38 129 55 90 27 104
            29 169 20 52 -8 90 -7 142 3 38 7 138 24 220 37 181 31 324 59 465 92 58 14
            242 56 410 94 171 38 349 85 405 105 55 21 215 77 355 125 140 48 314 109 385
            135 272 99 700 235 914 290 36 9 90 29 120 44 45 23 75 29 151 34 111 7 523
            108 944 231 349 103 485 164 605 272 80 72 102 118 110 227 4 51 11 100 16
            108 5 7 10 68 10 134 1 99 4 126 20 155 14 26 20 57 21 120 0 49 8 106 18 134
            9 27 16 73 16 102 0 37 7 64 22 91 17 29 23 54 23 103 0 49 6 77 25 115 25 50
            26 68 19 215 -1 19 7 71 18 115 12 44 29 132 38 195 9 63 25 147 36 186 36
            131 18 216 -62 296 -49 49 -246 149 -1564 799 -121 60 -386 194 -590 299 -261
            134 -399 199 -470 222 -55 17 -107 37 -116 45 -22 19 -69 16 -93 -5 -20 -18
            -176 -95 -176 -87 0 2 7 37 15 77 22 101 51 304 71 486 21 202 15 716 -10 862
            -52 301 -166 588 -316 795 -31 44 -78 113 -104 153 -69 108 -190 228 -365 362
            -181 137 -287 239 -382 365 -66 88 -119 184 -119 216 0 9 12 24 26 33 41 28
            84 104 84 150 0 60 16 109 50 150 45 58 64 117 58 184 -5 55 -45 153 -68 167
            -19 12 -9 24 59 69 86 57 139 81 366 163 241 87 254 94 351 184 46 42 137 120
            202 172 251 202 358 360 398 588 19 109 14 242 -12 329 -25 85 -83 187 -127
            225 -82 70 -212 85 -334 39 -46 -17 -81 -24 -108 -21 -22 2 -80 -2 -130 -9
            -288 -41 -441 -22 -535 66 -38 35 -38 59 0 153 16 40 34 105 40 143 30 192
            -66 384 -250 503 -109 70 -233 96 -343 70z m230 -158 c77 -36 168 -124 204
            -197 18 -37 25 -70 27 -134 4 -79 1 -94 -32 -179 -20 -51 -36 -111 -36 -132 0
            -32 -9 -50 -45 -95 -74 -91 -107 -166 -113 -257 -6 -96 13 -152 75 -218 152
            -163 457 -150 724 32 88 60 228 196 250 243 15 34 26 43 79 63 110 41 186 26
            239 -48 49 -67 73 -157 74 -274 1 -252 -92 -412 -368 -635 -66 -54 -156 -130
            -199 -170 -88 -81 -84 -79 -329 -166 -195 -70 -324 -131 -405 -192 l-53 -40
            -51 55 c-42 44 -75 65 -175 113 -68 33 -161 69 -205 80 -45 11 -84 22 -86 25
            -3 3 107 5 244 5 230 0 253 2 289 20 32 16 50 19 105 14 64 -6 67 -6 88 21 29
            37 28 61 -4 90 l-27 25 73 0 c54 0 78 4 92 17 24 21 23 68 -2 93 -18 18 -33
            20 -146 20 l-125 0 20 26 c27 34 27 62 -1 91 -26 28 -57 29 -91 3 -14 -11 -28
            -20 -32 -20 -4 0 -19 -8 -33 -19 -15 -10 -103 -44 -197 -76 -93 -31 -207 -72
            -252 -91 -88 -37 -113 -41 -113 -21 0 22 70 159 108 211 80 109 303 343 364
            382 43 28 62 57 53 84 -18 56 -68 64 -136 22 -50 -32 -262 -250 -350 -360
            -101 -125 -179 -289 -179 -375 0 -67 -9 -74 -72 -53 -29 10 -87 20 -128 23
            -47 4 -94 15 -125 30 -27 12 -69 23 -92 23 -35 0 -50 7 -82 35 -21 19 -44 35
            -50 35 -22 0 -10 16 60 85 38 39 95 107 126 152 47 69 56 90 60 135 2 29 11
            77 20 107 11 38 14 71 9 115 -4 33 -8 104 -9 156 -2 86 -6 101 -36 160 -55
            108 -88 130 -139 94 -31 -21 -28 -62 8 -131 50 -98 51 -150 2 -83 -32 43 -70
            40 -181 -15 -60 -30 -112 -66 -153 -105 -34 -33 -63 -58 -64 -57 -5 6 -44 87
            -44 92 0 3 10 5 23 5 22 0 70 25 181 93 33 21 68 37 78 37 26 0 58 36 58 65 0
            34 -33 65 -69 65 -39 0 -108 -30 -179 -78 -31 -22 -72 -47 -90 -56 -18 -9 -36
            -26 -40 -37 -6 -15 -22 2 -89 99 -45 64 -121 164 -169 222 -116 140 -161 210
            -186 293 -48 160 15 279 154 294 114 12 327 -69 529 -201 54 -36 112 -72 127
            -80 25 -13 31 -25 41 -75 25 -127 124 -273 219 -320 66 -34 144 -44 221 -31
            128 22 222 81 297 186 96 134 130 266 121 464 -3 69 -8 133 -12 142 -5 12 6
            26 42 52 98 73 193 81 310 27z m-468 -271 c3 -187 -84 -365 -210 -435 -48 -27
            -63 -30 -141 -30 -81 0 -88 2 -125 31 -38 31 -93 111 -111 163 l-10 28 77 -5
            c207 -12 359 81 462 282 40 79 45 84 50 59 4 -16 7 -57 8 -93z m805 -606 c97
            -51 242 -65 446 -44 52 6 104 13 115 16 29 9 -78 -99 -150 -151 -129 -92 -247
            -135 -370 -135 -227 0 -311 152 -185 336 l32 45 31 -21 c18 -11 54 -32 81 -46z
            m-1419 -404 c3 -10 3 -34 0 -53 -6 -30 -12 -35 -58 -49 -45 -14 -177 -84 -284
            -150 l-33 -20 0 61 c0 58 1 61 25 61 29 0 282 122 310 150 24 25 32 25 40 0z
            m-62 -272 c-10 -18 -61 -77 -114 -132 l-96 -100 -32 44 c-17 24 -31 46 -31 49
            0 10 264 169 283 170 5 1 0 -14 -10 -31z m1151 -363 c6 -9 29 -22 50 -29 l40
            -14 -32 -16 c-25 -13 -61 -16 -179 -15 l-148 0 120 44 c140 51 135 49 149 30z
            m-1317 -27 c1 -13 20 -39 42 -59 37 -33 40 -39 31 -65 -5 -16 -14 -60 -19 -98
            -6 -37 -11 -70 -13 -72 -2 -1 -26 3 -53 10 l-50 12 -6 75 c-4 42 -12 92 -19
            113 -15 46 -9 61 32 86 42 26 53 25 55 -2z m857 -153 c19 -30 17 -30 -28 -8
            -36 17 -39 33 -7 33 10 0 26 -11 35 -25z m-1057 -79 c-4 -10 -19 -16 -42 -16
            -32 0 -35 2 -35 29 0 21 10 37 33 57 l32 27 9 -41 c5 -22 6 -48 3 -56z m460
            82 c-53 -143 -72 -188 -79 -188 -9 0 -4 55 13 148 10 49 12 52 41 52 20 0 29
            -4 25 -12z m249 -49 c21 -7 21 -8 3 -66 -10 -32 -23 -78 -29 -102 l-11 -44
            -66 7 c-37 4 -72 10 -79 14 -16 9 46 180 72 199 18 13 63 10 110 -8z m264 -80
            c41 -18 77 -34 79 -36 2 -1 -8 -21 -22 -45 -37 -64 -106 -138 -128 -138 -22 0
            -99 48 -99 62 0 17 41 161 51 180 12 23 17 22 119 -23z m283 -96 c15 -7 27
            -18 27 -25 0 -32 -135 -219 -158 -218 -4 0 -22 13 -41 29 l-35 28 48 64 c27
            35 58 83 69 107 23 45 29 46 90 15z m-1323 -7 c0 -2 -16 -37 -35 -78 -19 -41
            -40 -102 -47 -134 -17 -73 -34 -76 -66 -12 -20 40 -22 52 -13 84 13 48 106
            144 138 144 13 0 23 -2 23 -4z m221 -31 c4 -3 -8 -35 -27 -73 -34 -66 -63
            -177 -64 -239 0 -31 -2 -33 -34 -33 -19 0 -43 8 -54 18 l-21 17 23 85 c12 47
            37 113 54 147 18 34 32 70 32 79 0 14 7 15 42 10 22 -4 44 -9 49 -11z m295
            -87 l36 -21 -28 -15 c-82 -41 -114 -101 -114 -212 0 -44 -3 -80 -7 -80 -5 0
            -36 10 -70 22 l-63 23 0 67 c0 77 25 154 69 216 l29 39 56 -8 c30 -5 72 -19
            92 -31z m989 24 c11 -9 29 -27 39 -39 18 -22 17 -23 -18 -82 -20 -32 -51 -76
            -70 -97 l-33 -37 -41 34 -40 33 27 36 c16 19 45 65 67 102 21 37 41 68 44 68
            3 0 14 -8 25 -18z m-688 -95 c8 -5 -7 -25 -37 -53 -53 -50 -104 -140 -115
            -202 -9 -52 -40 -68 -81 -41 -36 23 -42 40 -43 115 -1 70 9 84 92 124 40 19
            54 32 60 56 l9 32 52 -12 c29 -7 57 -16 63 -19z m201 -87 l34 -21 -32 -35
            c-17 -20 -46 -65 -63 -101 l-32 -65 -55 7 c-30 4 -59 10 -64 15 -12 12 -1 50
            26 90 21 31 134 130 148 130 3 0 20 -9 38 -20z m683 -58 c14 -41 6 -83 -22
            -121 l-21 -29 -32 19 c-17 10 -42 28 -55 38 l-25 19 31 35 c17 19 41 54 54 76
            l23 42 18 -23 c10 -13 23 -38 29 -56z m-437 -56 l28 -24 -43 -55 c-24 -31 -51
            -65 -59 -77 -15 -20 -16 -20 -70 -4 -30 9 -57 18 -58 19 -2 2 11 32 29 66 51
            95 114 123 173 75z m-1390 -18 c22 -7 49 -23 59 -35 32 -40 86 -60 179 -66 62
            -4 108 -14 165 -36 59 -23 94 -31 145 -31 61 0 69 -3 108 -35 49 -41 82 -44
            140 -15 49 25 74 25 196 0 239 -49 343 -87 435 -160 27 -21 79 -50 117 -64 67
            -26 70 -28 76 -66 15 -98 156 -300 313 -451 55 -52 161 -141 236 -198 173
            -130 260 -218 343 -344 36 -55 85 -126 109 -158 123 -168 234 -456 280 -729
            22 -130 31 -563 16 -740 -22 -254 -67 -539 -112 -706 -10 -39 -13 -41 -94 -73
            -46 -18 -197 -70 -337 -116 -139 -46 -300 -101 -358 -123 -131 -50 -344 -146
            -379 -171 -20 -15 -43 -19 -96 -18 -65 1 -79 -3 -206 -61 -75 -34 -246 -104
            -380 -156 -271 -104 -544 -217 -601 -249 -21 -11 -41 -18 -44 -14 -4 3 5 26
            20 51 14 24 26 56 26 70 0 31 -36 66 -67 66 -27 0 -42 -15 -92 -95 -28 -45
            -62 -79 -132 -132 -94 -72 -94 -72 -269 -122 -96 -27 -184 -53 -195 -56 -11
            -4 32 28 95 70 177 120 295 210 323 247 84 112 82 208 -4 208 -35 0 -56 -21
            -69 -68 -12 -48 -55 -88 -205 -194 -346 -243 -722 -435 -1080 -551 -61 -20
            -117 -38 -126 -42 -19 -7 -39 -47 -39 -76 0 -42 -31 -31 -144 54 -62 47 -142
            111 -177 142 -75 67 -180 186 -176 199 2 5 38 18 81 28 54 12 167 60 355 149
            513 244 803 417 978 584 78 75 88 100 53 135 -36 36 -68 25 -155 -50 -196
            -172 -476 -335 -988 -575 -162 -76 -234 -104 -325 -126 l-117 -29 -48 48 c-85
            84 -264 377 -235 386 7 2 52 9 100 15 159 21 260 53 573 184 164 68 239 106
            301 152 93 70 115 114 74 155 -26 26 -72 26 -96 -2 -39 -44 -155 -109 -310
            -173 -348 -145 -426 -169 -638 -195 -78 -10 -91 -9 -107 6 -25 22 -62 101 -54
            114 3 5 26 10 50 10 85 0 434 85 625 152 131 46 273 122 324 174 37 36 5 107
            -48 107 -13 0 -45 -15 -71 -33 -75 -52 -220 -116 -361 -159 -172 -52 -433
            -109 -513 -112 l-65 -3 -32 58 c-59 108 -128 353 -166 591 -19 120 -22 181
            -23 425 0 255 2 297 23 402 41 215 163 609 249 808 234 539 540 953 956 1294
            227 186 471 328 687 400 145 48 266 71 540 101 129 14 240 28 245 30 23 8 100
            4 139 -7z m1726 -244 c0 -34 -6 -51 -27 -74 l-28 -31 -70 28 c-39 16 -94 47
            -122 70 l-52 41 34 48 c19 27 49 56 66 66 l30 16 85 -60 c83 -60 84 -61 84
            -104z m1566 -3615 c65 -20 355 -161 750 -366 93 -48 235 -119 315 -158 130
            -63 695 -343 1254 -622 116 -57 221 -112 234 -122 23 -18 23 -18 -90 -44 -376
            -87 -802 -175 -1056 -218 -161 -26 -374 -67 -474 -89 -99 -23 -183 -39 -186
            -37 -2 3 11 8 29 12 18 4 137 41 263 82 184 61 248 77 320 83 50 5 153 21 230
            38 77 16 212 42 300 56 104 18 178 36 211 52 44 21 60 23 108 18 68 -8 96 10
            96 62 0 39 -21 56 -93 73 -30 7 -76 33 -128 71 -44 33 -118 80 -165 106 -82
            44 -321 148 -373 161 -14 3 -46 19 -70 35 -25 15 -54 28 -64 28 -11 0 -38 16
            -60 36 -22 20 -83 56 -136 81 -53 24 -204 103 -336 174 -270 145 -376 188
            -482 196 -68 6 -71 7 -77 34 -10 52 -74 102 -193 152 -60 26 -141 52 -179 58
            -63 11 -79 10 -165 -10 -51 -13 -101 -25 -109 -28 -39 -14 13 20 95 61 103 52
            132 55 231 25z m-439 -150 c-21 -17 -44 -43 -52 -58 -13 -25 -21 -28 -103 -39
            -78 -9 -108 -19 -214 -70 -145 -71 -283 -120 -468 -168 -74 -20 -161 -47 -193
            -61 -43 -20 -61 -23 -69 -15 -24 24 270 143 697 283 135 44 287 98 338 119 51
            22 94 39 97 40 2 0 -12 -14 -33 -31z m445 -49 c93 -35 183 -84 186 -101 2 -13
            -16 -22 -80 -39 -164 -45 -301 -37 -435 25 -57 26 -62 34 -35 57 78 69 260 97
            364 58z m-453 -199 c68 -41 126 -65 210 -86 109 -26 272 -17 411 24 58 17 126
            34 151 37 101 14 207 -27 526 -202 98 -53 225 -119 283 -146 57 -27 110 -54
            116 -59 10 -8 5 -23 -23 -66 -86 -135 -125 -260 -128 -407 l-1 -100 -115 -38
            c-63 -22 -169 -54 -236 -73 -132 -37 -168 -52 -197 -84 -11 -12 -27 -21 -37
            -21 -28 0 -540 262 -583 299 -9 7 -16 28 -16 45 0 91 -90 262 -197 377 l-73
            77 31 6 c18 3 49 25 75 52 41 42 48 45 82 40 135 -20 149 -19 176 7 28 28 32
            47 16 77 -13 24 -57 37 -151 45 -97 8 -147 -5 -196 -52 -21 -20 -47 -38 -57
            -40 -28 -5 -56 -33 -56 -55 0 -11 -3 -18 -7 -16 -5 2 -31 15 -60 31 -78 41
            -191 74 -307 92 -130 19 -203 19 -302 0 -79 -15 -109 -29 -155 -72 -19 -18
            -33 -22 -52 -18 -28 7 -83 60 -70 68 4 2 77 23 161 46 199 54 343 106 470 170
            95 48 157 65 241 70 13 0 45 -12 70 -28z m-1191 -263 c53 -29 122 -75 152
            -104 70 -66 109 -85 184 -92 42 -4 79 -15 121 -38 33 -18 86 -40 118 -49 31
            -9 57 -20 57 -25 0 -4 19 -27 41 -50 28 -29 75 -58 144 -90 131 -62 193 -80
            271 -80 l62 0 82 -84 c87 -90 125 -114 188 -120 24 -3 50 -14 66 -29 44 -41
            423 -242 569 -303 l79 -32 -84 -32 c-45 -18 -209 -76 -363 -130 -154 -54 -334
            -118 -400 -142 -522 -196 -580 -220 -613 -261 -11 -13 -27 -15 -81 -10 -79 7
            -78 7 -516 -192 -345 -156 -533 -235 -737 -309 -86 -31 -196 -75 -245 -97 -48
            -23 -158 -68 -243 -101 -173 -66 -248 -101 -333 -152 l-59 -36 -41 29 c-23 16
            -94 72 -157 126 -63 53 -162 135 -219 183 -58 48 -152 128 -210 178 -58 49
            -162 132 -231 184 -334 250 -478 369 -573 479 -76 85 -117 123 -198 177 -57
            38 -105 75 -107 82 -5 15 56 42 77 34 9 -3 42 -34 74 -68 32 -35 106 -95 165
            -135 59 -39 144 -99 189 -131 46 -33 108 -70 138 -83 43 -17 62 -32 84 -66 37
            -59 69 -89 178 -171 51 -38 130 -110 176 -160 l84 -90 59 -7 c52 -5 59 -9 62
            -29 2 -13 34 -54 70 -91 37 -37 119 -120 182 -185 63 -65 129 -125 145 -134
            47 -25 112 -19 183 17 94 47 211 118 268 162 28 21 60 39 70 39 11 0 38 9 59
            21 22 11 78 36 125 54 47 18 156 65 242 103 147 66 667 269 788 307 30 9 76
            28 102 41 26 13 60 24 75 24 45 0 100 19 132 45 16 14 34 25 39 25 16 0 42 41
            42 65 0 12 -11 32 -25 45 -30 28 -71 23 -121 -15 -47 -36 -73 -32 -109 16 -51
            69 -196 168 -247 169 -10 0 -24 14 -31 33 -21 49 -60 85 -212 192 -77 54 -196
            141 -265 193 -68 51 -160 112 -205 134 -127 64 -173 98 -285 210 -97 97 -111
            106 -183 133 -55 20 -74 31 -64 36 33 19 297 128 512 211 289 112 353 138 456
            188 46 22 98 40 117 40 21 0 68 -19 130 -52z m760 -98 c304 -35 491 -157 617
            -403 25 -49 44 -92 41 -94 -10 -10 -104 83 -186 181 -100 120 -118 136 -198
            182 -102 59 -166 74 -305 74 -107 0 -128 3 -172 24 l-50 23 35 7 c104 18 117
            18 218 6z m2279 -162 c-3 -7 -26 -58 -51 -113 -46 -101 -86 -234 -86 -286 0
            -27 -15 -47 -27 -36 -10 11 -3 133 12 190 25 97 114 257 142 257 9 0 13 -6 10
            -12z m-2104 -70 c70 -32 116 -68 67 -53 -14 4 -65 27 -115 51 -65 31 -80 42
            -55 38 19 -3 65 -20 103 -36z m2343 -29 c53 -22 129 -56 168 -76 82 -41 210
            -122 204 -128 -3 -3 -58 -14 -124 -25 -65 -12 -198 -37 -295 -56 -96 -18 -180
            -34 -186 -34 -16 0 -7 57 23 148 27 83 90 212 103 212 5 0 53 -18 107 -41z
            m-4667 -85 c-19 -19 -295 -118 -689 -248 -162 -54 -319 -111 -347 -128 -38
            -21 -99 -39 -210 -64 -87 -18 -165 -33 -173 -33 -47 1 671 243 986 332 183 52
            358 115 399 143 11 8 26 14 34 14 13 0 13 -2 0 -16z m258 -136 c18 -10 42 -32
            53 -48 l21 -29 -23 -73 c-18 -58 -23 -96 -22 -188 0 -103 3 -125 32 -208 65
            -188 144 -269 287 -291 33 -5 86 -19 118 -30 150 -53 344 -59 523 -17 86 20
            118 23 144 16 59 -17 170 -99 170 -125 0 -7 -26 -20 -57 -30 -203 -66 -718
            -268 -940 -369 l-101 -47 -53 56 c-103 109 -308 172 -401 123 -12 -5 -47 -3
            -99 8 -59 12 -117 15 -213 12 -116 -3 -138 -7 -189 -30 -48 -23 -66 -26 -110
            -21 -49 6 -56 11 -120 80 -38 40 -120 111 -182 159 -63 47 -121 96 -128 108
            -13 20 -12 24 13 49 36 36 87 142 105 220 22 92 19 167 -10 254 -14 42 -24 77
            -22 80 3 2 150 53 328 113 479 160 652 224 700 257 l42 30 51 -19 c28 -11 66
            -29 83 -40z m5381 -195 c-5 -29 -163 -76 -358 -108 -52 -9 -230 -45 -395 -80
            -165 -35 -356 -76 -425 -90 -69 -15 -246 -55 -395 -90 -148 -35 -291 -66 -316
            -70 -45 -6 -47 -5 -57 24 l-11 30 127 30 c239 56 367 82 657 131 256 43 621
            118 1015 209 151 35 163 36 158 14z m-5187 -6 c-2 -20 -35 -126 -38 -123 -8 8
            20 137 29 134 5 -2 9 -7 9 -11z m209 -110 c41 -23 129 -83 195 -133 66 -50
            182 -134 258 -188 75 -53 137 -101 137 -106 0 -10 -15 -15 -97 -31 -83 -15
            -280 -6 -356 16 -50 16 -60 23 -72 52 -8 19 -44 66 -79 105 -82 90 -96 119
            -96 201 0 62 15 127 29 127 3 0 40 -19 81 -43z m4934 -94 c-4 -21 -8 -39 -9
            -41 -1 -1 -42 -9 -91 -17 -49 -8 -127 -24 -174 -36 -191 -46 -662 -140 -920
            -184 -151 -25 -313 -55 -360 -66 -100 -23 -244 -72 -265 -89 -22 -18 -18 -66
            7 -90 26 -24 58 -25 113 -4 92 36 225 67 480 109 149 26 315 55 370 66 222 45
            526 110 625 133 58 13 124 28 148 31 47 7 50 2 32 -52 -10 -27 -16 -30 -88
            -42 -72 -12 -277 -63 -527 -131 -107 -29 -502 -113 -873 -185 -100 -19 -208
            -44 -239 -55 -36 -12 -59 -16 -63 -10 -3 6 -12 65 -19 133 -7 67 -14 132 -17
            144 -3 19 2 22 49 28 28 3 153 31 277 60 124 30 365 83 535 120 171 36 370 79
            444 95 74 17 200 41 280 55 80 14 175 33 211 44 36 10 69 19 73 20 4 0 5 -16
            1 -36z m-6639 -77 c16 -55 18 -110 5 -141 -6 -14 -9 -5 -9 36 -1 31 -10 82
            -21 114 -11 32 -20 63 -20 69 1 22 31 -30 45 -78z m4790 54 c-11 -5 -29 -8
            -40 -8 -16 0 -15 2 5 8 34 11 60 11 35 0z m-4944 -77 c18 -56 21 -79 15 -147
            -4 -45 -10 -96 -14 -115 l-7 -34 -80 56 c-44 31 -131 90 -194 133 -123 82
            -123 81 -39 109 48 17 250 63 280 64 12 1 23 -18 39 -66z m-805 -90 c26 -52
            40 -65 133 -128 81 -54 124 -93 198 -176 94 -107 222 -214 573 -478 68 -51
            176 -138 240 -191 63 -54 163 -138 221 -186 58 -49 149 -125 201 -169 52 -44
            110 -93 129 -108 61 -50 -10 -8 -91 53 -41 31 -109 76 -150 101 -74 44 -355
            249 -624 457 -192 148 -233 174 -331 216 -88 37 -220 121 -335 214 -67 54
            -211 131 -289 153 l-43 13 4 66 c8 105 69 220 116 220 13 0 28 -19 48 -57z
            m5583 -105 c23 -123 53 -465 61 -700 5 -139 14 -356 20 -483 8 -171 8 -248 -1
            -299 -7 -44 -9 -108 -5 -172 4 -57 4 -146 0 -198 -7 -95 -7 -96 -40 -115 -18
            -11 -77 -31 -131 -46 -265 -72 -619 -185 -948 -305 -229 -83 -407 -141 -414
            -135 -2 3 8 29 22 57 22 44 28 77 38 188 17 203 23 554 11 590 -8 23 -8 40 3
            71 42 118 -13 279 -95 279 -61 0 -82 -52 -46 -113 28 -48 32 -77 15 -124 -8
            -25 -19 -33 -55 -42 -24 -6 -162 -49 -307 -95 -469 -150 -865 -258 -1277 -346
            -276 -59 -318 -71 -428 -116 -65 -27 -91 -33 -119 -28 -34 6 -35 8 -28 43 4
            20 16 62 27 95 20 58 20 58 1 85 -11 14 -32 27 -47 29 -43 5 -61 -16 -96 -111
            l-10 -28 -97 51 c-113 58 -438 280 -668 455 -354 269 -885 662 -962 710 -45
            29 -111 65 -148 80 l-67 29 7 175 c9 224 15 257 40 253 50 -9 189 -86 276
            -154 54 -41 119 -89 147 -107 37 -23 54 -42 66 -74 19 -50 42 -73 231 -233 77
            -65 239 -206 360 -314 559 -496 793 -670 907 -670 27 0 41 6 52 22 34 48 13
            86 -62 112 -100 36 -358 236 -747 580 -218 193 -211 186 -203 186 4 0 73 -50
            155 -112 81 -62 177 -134 213 -161 36 -27 102 -71 148 -98 45 -27 115 -74 155
            -104 129 -99 235 -134 321 -105 38 12 62 49 52 77 -12 31 161 125 384 209 69
            25 172 68 230 94 58 26 164 69 235 94 220 79 327 124 705 294 538 242 500 229
            538 190 34 -37 43 -71 26 -103 -20 -38 -18 -71 6 -95 83 -83 181 101 116 219
            -12 22 -11 26 16 49 33 28 565 231 973 372 149 52 299 106 335 121 93 39 97
            38 109 -23z m1967 -122 c8 -22 -15 -93 -32 -100 -9 -4 -105 -20 -213 -36 -268
            -41 -407 -69 -751 -155 -162 -40 -351 -85 -420 -100 -69 -14 -171 -37 -227
            -50 -133 -32 -133 -32 -133 8 0 30 4 34 63 58 35 15 144 43 252 63 212 41 580
            116 725 148 52 11 182 45 288 75 168 47 391 100 430 102 7 1 15 -5 18 -13z
            m-56 -269 c0 -13 -8 -31 -17 -40 -11 -9 -150 -44 -353 -87 -184 -39 -468 -105
            -630 -145 -250 -63 -524 -123 -672 -149 l-38 -7 0 45 c0 42 2 46 28 52 15 3
            61 14 102 25 41 10 131 30 200 44 69 15 280 64 470 111 330 81 469 110 735
            152 167 27 175 27 175 -1z m-40 -203 c0 -4 -5 -23 -11 -43 l-11 -36 -231 -57
            c-128 -32 -268 -64 -312 -72 -44 -9 -179 -34 -300 -56 -121 -23 -347 -68 -502
            -102 -156 -34 -285 -60 -287 -57 -3 2 -6 17 -8 33 l-3 29 235 47 c129 26 354
            78 500 115 261 66 864 201 908 204 12 0 22 -2 22 -5z m-5538 -93 c20 -7 58
            -31 85 -55 26 -23 72 -55 101 -71 79 -43 81 -48 27 -88 -84 -60 -243 -140
            -269 -133 -13 3 -85 73 -161 155 -76 83 -144 152 -151 155 -26 10 -14 23 34
            40 37 13 73 15 172 12 69 -2 142 -9 162 -15z m313 -25 c66 -31 113 -75 94 -87
            -10 -6 -26 3 -54 30 -22 22 -58 48 -80 59 -55 29 -21 27 40 -2z m5185 -130 c0
            -40 -39 -116 -59 -116 -10 0 -177 -32 -372 -70 -195 -39 -487 -95 -649 -126
            -162 -30 -337 -66 -388 -79 -52 -14 -107 -25 -123 -25 -28 0 -29 2 -29 49 0
            42 3 50 23 54 118 29 690 147 912 188 154 28 363 73 465 99 221 57 220 57 220
            26z m-7552 -30 c87 -44 442 -302 1062 -774 228 -173 565 -399 678 -454 101
            -50 107 -56 94 -91 -6 -14 -33 0 -178 96 -94 63 -233 161 -310 219 -76 57
            -227 167 -334 244 -107 78 -279 203 -383 280 -103 77 -238 171 -300 210 -61
            38 -175 116 -252 173 -77 57 -146 106 -152 108 -16 6 -17 23 -3 23 6 0 41 -15
            78 -34z m22 -222 c58 -44 163 -115 233 -159 71 -44 190 -126 265 -183 75 -57
            232 -171 347 -254 116 -83 287 -208 380 -278 165 -123 468 -330 533 -364 30
            -16 32 -20 32 -72 0 -41 -53 -296 -65 -312 -1 -1 -26 10 -56 25 -36 19 -70 28
            -107 30 -53 1 -55 3 -136 80 -115 110 -408 371 -491 438 -38 31 -131 97 -205
            148 -74 51 -180 127 -235 170 -55 43 -118 90 -141 104 -32 20 -43 36 -52 69
            -14 53 -52 97 -166 191 -75 62 -94 73 -115 68 -33 -8 -51 -31 -51 -66 0 -26
            21 -51 115 -134 38 -33 23 -26 -90 47 -133 85 -136 88 -151 124 -11 26 -13 58
            -8 129 4 52 9 95 11 95 10 0 192 -118 260 -169 108 -80 549 -444 843 -696 135
            -116 288 -245 340 -287 206 -164 364 -265 400 -253 31 10 50 34 50 65 0 32 -7
            38 -154 136 -125 82 -218 157 -596 479 -305 260 -387 328 -658 548 -143 115
            -272 207 -363 260 -51 29 -99 80 -99 104 0 11 4 13 13 6 6 -6 59 -46 117 -89z
            m7490 -38 l0 -43 -82 -6 c-46 -3 -128 -16 -183 -30 -392 -101 -1276 -262
            -1300 -238 -3 2 -5 15 -5 27 0 19 7 24 38 29 20 4 91 20 157 35 66 16 172 38
            235 50 164 30 973 188 1045 204 96 22 95 22 95 -28z m-20 -201 c0 -34 -21 -73
            -57 -108 l-29 -27 -67 17 c-64 17 -69 17 -209 -6 -79 -12 -303 -63 -498 -111
            -195 -49 -422 -102 -505 -119 -82 -17 -160 -34 -172 -37 -22 -6 -23 -3 -23 42
            0 27 -3 58 -6 69 -5 19 2 21 118 33 200 19 957 168 1333 262 17 5 49 8 73 9
            39 1 42 -1 42 -24z m-320 -266 c-39 -18 -394 -125 -625 -189 -192 -54 -519
            -130 -567 -132 -50 -3 -53 -2 -56 23 -3 24 1 26 85 47 48 12 142 33 208 46
            140 29 209 46 478 115 201 52 466 110 502 110 12 0 2 -9 -25 -20z m-2960 -150
            c0 -38 -2 -69 -5 -69 -3 0 -17 7 -30 16 -23 15 -31 13 -162 -34 -201 -73 -336
            -113 -728 -213 -192 -50 -438 -114 -545 -144 -107 -29 -289 -76 -405 -104
            -115 -29 -241 -61 -280 -72 -38 -11 -78 -23 -87 -25 -16 -5 -18 3 -18 70 0 42
            3 76 8 76 4 -1 18 -7 32 -15 40 -23 75 -18 178 26 99 42 194 68 497 134 372
            80 823 205 1285 354 135 43 248 77 253 74 4 -2 7 -35 7 -74z m-5 -237 c-4 -49
            -10 -94 -13 -99 -4 -6 -70 -31 -147 -56 -143 -46 -339 -93 -700 -167 -216 -45
            -849 -188 -1087 -246 -81 -20 -178 -40 -214 -44 -37 -5 -74 -15 -82 -24 -24
            -23 -19 -76 8 -98 31 -25 82 -19 300 33 334 80 1040 238 1220 274 270 53 560
            127 673 170 14 6 17 1 17 -32 0 -21 -8 -50 -17 -63 -9 -14 -17 -26 -18 -28 -2
            -3 -272 -73 -415 -107 -52 -13 -255 -60 -450 -104 -402 -91 -824 -202 -888
            -233 -24 -11 -50 -31 -59 -44 -8 -13 -23 -24 -33 -24 -10 0 -62 -13 -115 -29
            -78 -23 -104 -27 -124 -19 -14 6 -64 13 -111 17 l-85 6 1 55 c1 54 20 158 46
            254 13 48 15 49 73 67 33 11 202 55 375 99 173 43 398 102 500 130 102 28 311
            82 464 121 428 108 835 237 867 275 19 23 22 10 14 -84z m-145 -546 c0 -12
            -216 -72 -450 -125 -146 -33 -354 -80 -463 -105 -108 -25 -219 -47 -245 -50
            -59 -7 251 70 633 158 160 36 337 79 395 95 105 30 130 35 130 27z m-2381
            -307 c49 -31 51 -33 51 -78 l-1 -46 -32 36 c-32 36 -83 119 -73 119 2 0 27
            -14 55 -31z m186 -214 c-31 -13 -50 -11 -40 6 4 5 21 9 38 8 l32 -1 -30 -13z"/>
            <path d="M4812 7757 c-22 -19 -67 -44 -101 -56 -72 -26 -99 -58 -85 -99 5 -15
            21 -33 36 -40 23 -11 36 -8 109 20 105 39 159 88 159 141 0 26 -6 40 -22 51
            -33 23 -52 20 -96 -17z"/>
            <path d="M6994 7485 c-25 -25 -25 -27 -19 -133 11 -175 22 -258 38 -291 15
            -31 15 -31 -26 -66 -22 -19 -45 -35 -51 -35 -6 0 -58 -23 -116 -51 -89 -44
            -116 -64 -190 -138 -97 -97 -129 -154 -137 -240 -13 -137 53 -185 331 -241 55
            -11 102 -22 104 -25 8 -8 -18 -266 -33 -335 -8 -36 -22 -137 -31 -225 -15
            -150 -37 -291 -46 -300 -7 -7 -374 54 -402 67 -15 6 -29 22 -31 35 -3 13 -8
            35 -11 51 -10 49 -32 72 -67 72 -17 0 -37 -4 -44 -8 -19 -12 -31 -55 -22 -82
            4 -14 10 -42 14 -63 8 -47 57 -105 111 -128 23 -10 126 -32 229 -48 103 -17
            189 -32 191 -34 2 -2 -6 -61 -17 -131 l-22 -127 23 -24 c28 -29 59 -32 89 -7
            17 14 26 43 43 150 l22 134 29 -6 c16 -3 83 -6 148 -6 l119 0 0 -52 c0 -29 -9
            -107 -20 -173 -25 -148 -25 -177 -2 -198 23 -21 75 -22 92 -2 20 24 60 271 60
            370 l0 90 67 35 c86 44 206 133 261 194 79 86 119 214 110 353 -9 136 -49 212
            -152 289 -34 26 -92 62 -129 81 -51 25 -67 38 -63 50 3 8 11 70 16 137 6 66
            18 175 27 241 11 86 14 152 9 233 l-7 113 73 -7 c82 -7 124 -24 133 -53 3 -10
            1 -48 -5 -83 -5 -34 -10 -91 -10 -126 0 -54 4 -67 25 -87 29 -29 55 -32 85 -7
            19 15 22 32 31 157 5 77 7 155 4 173 -7 38 -64 106 -109 129 -17 9 -76 21
            -131 27 l-100 11 4 158 c3 182 -4 207 -63 207 -51 0 -66 -24 -66 -106 0 -38
            -3 -112 -6 -165 l-7 -95 -106 -16 c-58 -8 -108 -13 -111 -11 -2 2 -9 70 -13
            151 -11 182 -19 215 -52 230 -36 17 -43 15 -71 -13z m360 -507 c17 -83 17
            -179 2 -288 -10 -63 -21 -168 -25 -233 -5 -65 -10 -120 -13 -122 -2 -2 -40 2
            -83 10 -44 8 -97 17 -118 20 l-39 6 6 47 c9 68 11 390 3 437 -6 37 -4 43 32
            82 35 39 45 44 117 57 112 20 111 20 118 -16z m-396 -362 c-2 -113 -6 -207 -9
            -211 -7 -6 -247 46 -292 64 -36 14 -41 29 -27 82 17 62 151 194 240 237 36 17
            71 32 79 32 11 0 13 -36 9 -204z m299 -413 c84 -25 84 -26 63 -109 -20 -78
            -50 -353 -50 -460 0 -39 9 -102 20 -142 11 -39 20 -74 20 -76 0 -23 -324 -45
            -346 -24 -4 4 0 54 9 110 9 57 19 141 22 188 4 47 15 130 26 185 11 55 24 154
            30 220 6 66 12 125 14 131 4 14 117 0 192 -23z m300 -155 c78 -58 98 -101 97
            -218 0 -76 -5 -105 -23 -147 -33 -75 -178 -210 -201 -187 -6 6 -15 37 -21 69
            -14 79 12 395 42 508 9 34 12 36 32 26 12 -6 46 -29 74 -51z"/>
            <path d="M4626 7394 c-59 -29 -103 -44 -128 -44 -46 0 -78 -28 -78 -68 0 -79
            111 -85 255 -13 100 49 125 71 125 106 0 31 -33 65 -62 64 -13 0 -63 -20 -112
            -45z"/>
            <path d="M4490 7019 c-62 -22 -102 -30 -146 -30 -67 1 -90 -11 -99 -51 -10
            -42 28 -74 97 -83 48 -6 69 -2 159 29 58 20 121 36 141 36 64 0 89 60 43 105
            -33 34 -89 32 -195 -6z"/>
            <path d="M4440 6669 c-150 -57 -195 -66 -265 -49 -58 14 -89 5 -100 -31 -13
            -39 3 -69 48 -89 74 -32 123 -34 217 -6 114 34 275 97 300 119 42 36 13 108
            -43 106 -18 -1 -88 -23 -157 -50z"/>
            <path d="M4537 6320 c-75 -59 -310 -127 -471 -137 -77 -5 -103 -10 -118 -25
            -22 -22 -23 -56 -2 -87 17 -23 20 -24 128 -18 152 7 356 57 466 113 102 52
            132 103 86 148 -30 31 -55 33 -89 6z"/>
            <path d="M4610 5995 c-120 -63 -369 -129 -534 -142 -87 -7 -116 -24 -116 -68
            0 -45 32 -65 106 -65 182 0 596 122 673 199 26 26 27 65 1 91 -28 28 -55 25
            -130 -15z"/>
            <path d="M4709 5669 c-30 -22 -71 -49 -91 -60 -97 -50 -357 -102 -487 -97 -79
            3 -111 -15 -111 -63 0 -50 39 -69 140 -69 100 0 294 30 390 61 147 47 300 149
            300 201 0 29 -38 68 -66 68 -11 0 -45 -18 -75 -41z"/>
            <path d="M4814 5388 c-77 -50 -198 -97 -342 -134 -150 -38 -172 -40 -232 -19
            -23 8 -53 15 -65 15 -33 0 -57 -32 -53 -71 4 -48 66 -81 163 -87 172 -11 614
            148 654 234 18 41 -16 94 -61 94 -8 0 -36 -14 -64 -32z"/>
            <path d="M5560 4840 c-11 -11 -20 -24 -20 -30 0 -22 -67 -83 -119 -109 -80
            -40 -368 -163 -556 -236 -173 -68 -258 -90 -305 -80 -23 5 -35 1 -55 -19 -30
            -30 -32 -56 -6 -87 17 -22 27 -24 98 -23 58 0 100 7 163 28 204 68 695 278
            775 331 68 46 135 133 135 177 0 35 -32 68 -65 68 -14 0 -34 -9 -45 -20z"/>
            <path d="M6238 4524 c-114 -105 -292 -237 -419 -311 -87 -51 -582 -299 -682
            -341 -35 -15 -51 -55 -36 -85 34 -64 60 -56 416 121 175 87 361 184 413 216
            221 135 470 350 470 407 0 28 -39 69 -66 69 -8 0 -51 -34 -96 -76z"/>
            <path d="M10925 3972 c-11 -2 -37 -17 -59 -34 -53 -42 -112 -49 -155 -17 -30
            22 -34 22 -59 8 -15 -8 -33 -27 -40 -41 -18 -38 -70 -90 -84 -86 -7 2 -22 22
            -33 45 -11 22 -26 48 -34 57 -21 25 -70 17 -94 -16 -19 -25 -19 -28 -3 -73 22
            -67 62 -114 113 -137 64 -28 103 -18 168 43 l52 49 70 0 c58 0 80 5 128 29 39
            20 68 28 85 25 35 -8 73 24 73 60 0 50 -72 99 -128 88z"/>
            <path d="M8423 4230 c-50 -10 -75 -23 -125 -62 -30 -24 -38 -36 -38 -63 0 -30
            7 -38 68 -81 97 -70 268 -252 251 -268 -10 -9 -257 203 -281 240 -15 25 -46
            53 -74 70 -27 15 -62 40 -78 56 -15 15 -39 28 -52 28 -32 0 -64 -34 -64 -68 0
            -33 55 -94 111 -123 49 -25 52 -30 31 -70 -27 -54 -24 -172 8 -229 54 -97 195
            -129 292 -65 23 16 50 28 58 27 8 0 90 -75 182 -166 l166 -166 -28 -20 c-16
            -12 -51 -30 -77 -42 -64 -27 -83 -46 -83 -78 0 -35 29 -60 70 -60 37 0 130 44
            205 98 60 43 75 59 75 86 1 12 10 32 21 45 l20 24 26 -24 c14 -13 43 -47 64
            -76 21 -28 47 -54 58 -58 21 -6 60 9 73 29 27 40 -20 125 -118 215 l-64 58 15
            36 c41 98 -13 224 -119 279 -55 29 -139 30 -186 2 -54 -31 -98 -14 -156 61
            -26 33 -85 99 -133 148 l-86 87 53 0 c43 0 58 5 77 25 30 29 32 55 7 85 -21
            26 -97 35 -169 20z m-30 -528 c-19 -14 -67 -10 -90 9 -7 7 -13 31 -13 56 l0
            44 61 -47 c56 -45 59 -49 42 -62z m589 -39 c25 -23 44 -63 30 -63 -9 0 -92 83
            -92 92 0 12 33 -3 62 -29z m-239 -4 c12 -7 56 -44 97 -83 41 -40 92 -85 112
            -102 34 -27 36 -32 25 -48 -19 -26 -33 -16 -172 123 -78 79 -113 121 -102 121
            10 0 28 -5 40 -11z"/>
            <path d="M6775 3623 c-34 -9 -69 -39 -105 -92 -40 -59 -60 -63 -98 -23 -34 36
            -55 44 -87 32 -37 -14 -49 -54 -29 -97 21 -42 69 -75 146 -99 52 -16 58 -16
            81 -1 14 9 47 46 74 82 39 53 52 65 68 59 98 -30 185 -2 185 61 0 14 -10 35
            -21 46 -17 17 -29 20 -61 16 -23 -3 -53 0 -73 9 -35 14 -46 15 -80 7z"/>
            <path d="M7433 2955 c-58 -17 -120 -55 -181 -110 -61 -55 -69 -91 -26 -124 41
            -32 38 -33 155 57 45 34 69 41 69 19 0 -22 34 -47 63 -47 25 0 67 40 67 64 0
            30 -23 88 -45 114 -31 37 -50 42 -102 27z"/>
            <path d="M10152 2851 c-20 -12 -31 -75 -32 -168 0 -80 -1 -83 -23 -83 -13 0
            -47 -7 -77 -16 -67 -20 -73 -12 -65 81 10 105 9 118 -15 137 -44 36 -95 10
            -110 -57 -13 -55 -12 -157 1 -210 16 -65 6 -90 -54 -128 -28 -19 -62 -45 -74
            -58 -34 -38 -103 -166 -103 -192 0 -47 82 -101 169 -113 l53 -7 -7 -71 c-4
            -39 -15 -134 -26 -212 -26 -203 -12 -274 58 -274 44 0 63 33 63 108 0 37 9
            135 20 217 11 83 20 170 20 194 l0 44 38 -6 c20 -3 49 -10 65 -16 l27 -11 -6
            -172 c-4 -138 -10 -183 -25 -220 -13 -31 -19 -69 -18 -110 1 -35 -2 -117 -6
            -184 -7 -120 -7 -121 16 -138 31 -21 65 -20 88 3 19 19 27 72 40 276 l6 99 65
            25 c120 46 200 159 200 283 0 103 -74 194 -182 226 -56 16 -58 18 -53 47 3 17
            12 109 20 205 8 96 19 198 24 225 16 84 13 237 -5 263 -16 23 -66 30 -92 13z
            m-57 -428 c-2 -21 -7 -82 -10 -136 -8 -128 -9 -129 -54 -121 -71 12 -71 11
            -71 65 0 28 -5 70 -11 93 -15 60 5 99 63 119 24 9 53 16 66 16 20 1 22 -3 17
            -36z m-265 -198 c0 -45 0 -45 -35 -45 -20 0 -35 5 -35 12 0 15 49 78 61 78 5
            0 9 -20 9 -45z m446 -289 c38 -38 42 -66 19 -123 -17 -41 -40 -70 -67 -84 -17
            -9 -18 -1 -18 116 0 143 6 151 66 91z"/>
            <path d="M9020 2246 c-41 -12 -119 -41 -173 -65 -54 -23 -225 -84 -380 -135
            -155 -52 -360 -121 -456 -155 -96 -34 -247 -82 -335 -107 -284 -80 -430 -123
            -490 -144 -33 -11 -81 -20 -108 -20 -76 0 -111 -52 -72 -108 13 -19 24 -22 83
            -22 43 0 90 8 127 20 32 12 199 61 369 110 171 49 357 105 415 126 58 20 267
            92 465 159 198 67 416 145 485 173 69 28 146 54 173 57 75 11 111 71 67 115
            -26 26 -74 25 -170 -4z"/>
            <path d="M2206 7785 c-24 -25 -29 -54 -12 -78 70 -96 66 -81 87 -303 33 -340
            19 -545 -46 -680 -42 -86 -162 -204 -208 -204 -8 0 -52 -19 -98 -42 -115 -57
            -194 -138 -254 -261 -54 -111 -74 -183 -82 -299 -10 -146 31 -330 105 -472 20
            -38 73 -121 119 -185 82 -115 127 -192 138 -241 5 -21 -1 -30 -37 -58 -69 -52
            -136 -140 -189 -247 -27 -55 -58 -108 -69 -118 -24 -20 -26 -69 -5 -87 12 -10
            15 -37 15 -121 l0 -108 -118 -119 c-289 -290 -683 -811 -1011 -1337 -52 -82
            -173 -267 -268 -410 -96 -142 -195 -300 -220 -349 -41 -82 -45 -95 -40 -142 3
            -28 15 -70 26 -92 l21 -41 -27 -52 c-82 -165 1 -400 174 -487 68 -35 113 -43
            170 -31 46 11 54 10 80 -8 15 -11 53 -28 83 -38 30 -10 109 -45 175 -78 139
            -69 170 -78 226 -66 37 9 44 7 73 -18 57 -50 250 -152 322 -171 38 -9 85 -29
            105 -44 47 -37 95 -55 160 -64 44 -6 63 -15 102 -50 54 -48 119 -74 187 -74
            33 0 52 -5 64 -19 31 -34 113 -63 170 -60 38 2 66 -4 100 -21 30 -14 74 -25
            115 -28 59 -4 74 -1 121 23 85 43 149 94 171 136 10 22 23 39 28 39 19 0 108
            101 200 228 59 82 125 160 163 194 40 35 80 83 104 124 24 41 65 90 106 127
            69 61 106 113 127 179 7 21 21 40 32 43 32 11 78 55 120 116 43 63 109 195
            109 216 0 8 12 18 28 24 15 6 40 22 56 37 53 50 390 512 430 589 13 26 43 66
            66 88 23 22 75 83 115 135 39 52 94 113 120 134 30 25 56 58 70 90 13 28 36
            61 53 73 49 36 81 86 119 185 50 130 49 192 -2 296 -30 60 -58 96 -126 163
            -83 82 -88 89 -83 121 3 18 1 53 -5 76 -10 36 -20 47 -64 72 -28 16 -110 48
            -182 70 -122 39 -255 88 -255 95 0 2 5 16 11 32 6 15 15 75 20 133 5 58 12
            136 15 174 12 126 -29 241 -138 388 -60 81 -101 124 -377 393 -194 189 -280
            331 -312 510 -17 98 -6 216 26 282 36 72 144 170 324 292 216 147 272 216 306
            378 37 175 2 365 -111 597 -72 148 -194 271 -291 291 -33 6 -113 -21 -127 -43
            -4 -8 -19 -6 -48 4 -52 18 -99 18 -146 -2 -41 -17 -35 -20 -86 51 -25 36 -75
            65 -112 65 -17 0 -51 -10 -75 -23 l-43 -23 -37 38 c-60 63 -116 50 -181 -41
            -24 -33 -47 -60 -53 -60 -5 0 -27 13 -49 29 -21 16 -53 34 -70 40 -19 6 -36
            21 -44 38 -7 16 -29 45 -49 66 -39 39 -80 47 -107 21z m589 -265 c22 0 54 9
            73 20 44 27 48 26 76 -19 47 -74 133 -101 208 -65 25 11 40 13 59 6 22 -9 25
            -15 21 -42 -3 -23 2 -37 19 -56 44 -47 92 -26 114 50 28 95 52 97 140 9 123
            -123 227 -421 209 -602 -15 -150 -65 -221 -234 -334 -58 -38 -133 -92 -167
            -118 l-62 -48 -33 17 c-39 20 -86 20 -593 4 -187 -6 -368 -16 -402 -21 -88
            -16 -186 -48 -215 -71 -55 -43 -62 -119 -15 -154 26 -19 30 -19 55 -5 15 8 33
            26 39 40 10 21 26 29 85 43 90 22 334 38 451 29 55 -4 161 1 285 12 236 21
            245 20 202 -45 -54 -81 -65 -130 -65 -285 0 -159 16 -228 85 -366 62 -123 127
            -200 374 -444 277 -274 343 -364 370 -505 l6 -34 -27 26 c-44 40 -108 79 -258
            158 -219 114 -297 136 -655 185 -102 14 -270 41 -375 60 -104 19 -254 42 -332
            52 l-143 17 -11 36 c-22 66 -61 135 -150 262 -149 211 -201 349 -202 533 -2
            255 129 466 324 521 116 33 272 185 320 314 48 128 53 165 54 385 0 116 -4
            257 -9 313 l-9 104 41 -21 c77 -39 149 -21 208 52 l28 35 35 -24 c23 -16 50
            -24 76 -24z m-568 -2635 c65 -9 204 -31 308 -50 188 -33 291 -49 515 -80 117
            -16 279 -51 292 -64 5 -4 -21 -26 -57 -49 -35 -23 -84 -66 -109 -95 -85 -99
            -166 -282 -181 -408 -6 -48 5 -165 22 -236 5 -22 2 -23 -58 -23 -84 0 -200 21
            -356 65 -70 19 -198 49 -284 65 -293 56 -440 126 -494 234 -23 45 -25 61 -25
            178 0 116 3 135 26 191 59 141 189 287 256 287 15 0 80 -7 145 -15z m1390
            -327 c72 -39 117 -72 172 -126 l74 -74 -6 -57 c-11 -123 -56 -161 -190 -161
            -146 0 -165 -19 -235 -234 l-47 -145 -110 43 -110 43 -17 74 c-23 106 -23 232
            2 303 58 170 148 283 275 348 39 20 75 36 82 37 6 0 56 -23 110 -51z m-1835
            -473 c104 -97 261 -160 508 -205 63 -11 189 -41 280 -65 173 -46 254 -60 408
            -70 70 -4 115 -14 175 -37 268 -101 270 -101 313 -64 22 18 40 60 83 192 l56
            169 100 6 c65 4 113 12 136 23 35 18 36 18 95 -12 32 -16 104 -44 159 -61 55
            -18 130 -44 168 -57 61 -23 67 -28 67 -54 0 -20 -10 -35 -35 -54 -26 -20 -39
            -42 -53 -89 -18 -56 -22 -62 -48 -65 -114 -10 -158 -46 -220 -179 -37 -80 -48
            -95 -82 -112 -53 -27 -103 -87 -138 -165 -24 -53 -34 -65 -58 -70 -15 -3 -46
            -25 -68 -48 -46 -48 -213 -304 -294 -448 -36 -64 -75 -116 -119 -160 l-66 -65
            50 105 c93 193 68 245 -135 284 -146 27 -185 3 -271 -169 -30 -60 -77 -139
            -103 -175 -143 -195 -144 -233 -5 -301 58 -28 71 -31 138 -28 54 3 86 11 124
            30 l50 27 -10 -39 c-5 -22 -16 -39 -23 -39 -25 0 -102 -80 -147 -153 -24 -40
            -70 -117 -102 -172 -32 -55 -87 -131 -121 -168 -75 -82 -103 -147 -111 -262
            -5 -68 -10 -86 -33 -113 -26 -32 -27 -32 -78 -22 -29 5 -87 9 -130 8 -88 -1
            -103 5 -220 84 -96 64 -130 78 -196 78 -42 0 -64 6 -92 25 -20 13 -52 27 -73
            30 -88 14 -361 111 -446 158 -26 15 -63 27 -82 27 -19 0 -49 10 -69 24 l-35
            23 46 12 c62 16 123 78 168 172 84 175 74 281 -32 326 -18 8 -70 16 -115 19
            -70 5 -85 3 -114 -15 -42 -27 -69 -73 -82 -141 -23 -113 -44 -172 -68 -195
            -33 -31 -34 -81 -2 -105 41 -31 -191 23 -254 60 -92 52 -132 62 -276 69 l-134
            6 -15 39 c-14 38 -14 41 19 105 19 36 129 208 245 383 115 174 267 405 337
            515 277 434 626 888 887 1156 41 42 77 77 79 77 3 0 31 -25 64 -55z m2628
            -409 c9 -12 9 -41 -1 -133 -12 -115 -7 -289 10 -363 6 -28 3 -45 -20 -91 -25
            -50 -55 -88 -62 -78 -7 9 12 250 23 303 13 64 7 106 -22 154 l-20 33 27 95
            c28 92 41 108 65 80z m155 -192 c20 -48 19 -89 -4 -148 -16 -43 -19 -46 -24
            -25 -11 41 -4 209 9 209 2 0 11 -16 19 -36z m-420 -70 c-42 -124 -55 -190 -65
            -332 -6 -73 -20 -176 -31 -230 -13 -61 -19 -112 -15 -140 5 -35 0 -53 -24
            -100 -34 -66 -69 -113 -70 -94 0 8 27 177 61 375 33 199 58 373 55 387 -10 50
            97 265 111 223 2 -6 -8 -46 -22 -89z m-287 -564 c-57 -337 -66 -431 -44 -495
            5 -15 -13 -46 -71 -124 -42 -57 -79 -99 -81 -92 -12 35 55 344 138 636 17 61
            33 144 37 185 5 57 11 78 26 91 15 11 20 27 19 53 -1 33 24 104 33 94 1 -1
            -24 -158 -57 -348z m-191 -58 c-89 -322 -131 -518 -142 -653 -10 -125 -31
            -202 -76 -291 -23 -43 -43 -77 -45 -75 -2 1 8 43 22 92 34 126 145 681 145
            732 1 27 -7 53 -19 70 l-20 28 74 110 c41 61 77 119 81 130 6 18 7 18 11 2 2
            -9 -12 -74 -31 -145z m546 99 c2 -10 -4 -29 -15 -42 -15 -18 -18 -19 -14 -6 3
            10 6 30 6 43 0 28 16 32 23 5z m-1153 -242 c27 -6 50 -15 50 -20 0 -5 -31 -72
            -70 -149 -38 -77 -70 -146 -70 -154 0 -22 -96 -78 -143 -83 -23 -3 -55 0 -70
            6 -33 13 -32 14 43 116 29 39 77 121 107 183 60 125 56 122 153 101z m316
            -390 c-25 -123 -60 -285 -80 -360 -19 -75 -40 -177 -46 -225 -7 -57 -20 -108
            -36 -142 -15 -29 -28 -51 -30 -49 -8 8 109 516 167 726 14 49 20 95 18 140 -3
            56 0 73 20 104 13 21 25 36 27 34 2 -2 -16 -105 -40 -228z m-187 -145 c-64
            -247 -149 -635 -148 -674 0 -19 4 -44 8 -55 6 -14 -3 -36 -31 -81 -42 -66 -78
            -106 -65 -70 33 87 92 383 132 667 14 97 31 196 39 221 12 42 93 165 102 156
            2 -3 -15 -76 -37 -164z m-2026 -74 c18 -17 -30 -141 -80 -208 -21 -28 -30 -33
            -61 -30 -43 3 -42 -2 -12 123 34 142 31 138 93 130 28 -4 56 -11 60 -15z
            m1757 -335 c-28 -168 -53 -283 -100 -454 -11 -41 -20 -102 -20 -135 0 -59 -3
            -64 -67 -155 -37 -51 -69 -89 -71 -83 -1 5 4 50 12 99 22 120 20 171 -5 212
            -11 18 -19 34 -17 35 31 14 49 35 82 95 42 79 57 165 39 231 -9 33 -5 44 80
            188 49 84 91 151 93 149 3 -2 -9 -84 -26 -182z m-2581 -26 c23 -58 122 -222
            184 -302 26 -34 43 -63 37 -65 -20 -7 -61 29 -88 79 -15 28 -58 98 -95 156
            -37 58 -77 123 -88 145 l-20 39 27 -3 c21 -2 30 -12 43 -49z m247 -96 c11 -32
            31 -78 43 -103 31 -58 127 -154 172 -170 29 -11 40 -23 66 -81 17 -38 30 -69
            27 -69 -2 0 -42 19 -89 42 -83 41 -85 43 -85 78 0 43 -28 96 -95 184 -27 35
            -62 86 -77 113 -15 26 -37 63 -50 82 l-22 35 44 -27 c38 -22 48 -35 66 -84z
            m-392 7 c18 -30 59 -95 90 -145 62 -97 60 -112 -8 -80 -91 43 -156 165 -141
            263 5 35 10 43 16 32 5 -8 24 -40 43 -70z m766 -49 c28 -13 36 -23 41 -53 7
            -47 54 -116 164 -243 99 -115 195 -265 168 -265 -12 0 -200 108 -238 136 -43
            32 -88 112 -136 238 -22 60 -51 134 -64 163 l-24 53 27 -6 c15 -3 43 -14 62
            -23z m273 -113 c15 -5 27 -16 27 -23 0 -7 3 -28 6 -46 l7 -34 -39 44 c-22 25
            -45 53 -53 64 -13 19 -13 20 5 13 10 -5 31 -13 47 -18z m227 -84 c25 -10 40
            -23 40 -33 0 -21 26 -71 112 -218 37 -62 65 -113 63 -113 -2 0 -36 37 -75 83
            -79 91 -208 297 -186 297 3 0 24 -7 46 -16z m253 -116 c49 -77 152 -277 128
            -248 -19 23 -87 134 -155 253 -37 64 -14 60 27 -5z m253 -59 c68 -45 71 -50
            77 -94 13 -95 110 -241 205 -310 34 -24 48 -44 63 -86 24 -70 24 -70 -11 -58
            -16 6 -44 30 -62 52 -18 23 -57 69 -86 102 -69 77 -144 191 -221 334 -34 63
            -61 116 -61 118 0 5 12 -2 96 -58z m594 -58 c0 -10 -9 -70 -20 -132 -11 -63
            -23 -154 -27 -202 -5 -73 -10 -90 -27 -103 -19 -13 -22 -12 -38 18 -29 56 -23
            109 23 197 28 54 43 99 49 142 9 71 18 99 31 99 5 0 9 -8 9 -19z m-186 -47
            c19 -7 20 -22 6 -60 -12 -30 -9 -32 -55 39 -17 27 -17 27 8 27 14 0 32 -3 41
            -6z m-794 -187 c0 -10 -54 18 -67 35 -5 7 -15 29 -21 48 -12 34 -11 34 38 -20
            27 -30 50 -58 50 -63z m280 -130 c0 -4 -13 -1 -29 7 -29 15 -81 70 -81 86 0 5
            -3 17 -6 27 -4 10 21 -11 54 -47 34 -36 61 -68 62 -73z"/>
            <path d="M2592 7014 c-15 -10 -22 -25 -22 -48 0 -57 17 -63 190 -69 86 -3 208
            -15 275 -27 189 -33 382 -50 449 -41 86 12 120 60 79 110 -17 20 -30 22 -168
            25 -106 3 -204 13 -335 35 -217 36 -428 43 -468 15z"/>
            <path d="M2397 6683 c-32 -36 -20 -86 26 -109 25 -14 101 -17 511 -21 265 -2
            496 -5 514 -5 61 -2 92 63 50 105 -24 24 -29 25 -264 32 -131 5 -333 7 -449 5
            -252 -4 -278 -3 -330 5 -32 5 -44 3 -58 -12z"/>
            <path d="M2385 5949 c-277 -18 -388 -39 -436 -85 -71 -68 3 -166 82 -106 38
            29 120 42 329 55 265 15 354 14 417 -7 68 -23 103 -18 122 16 44 77 -57 129
            -259 133 -63 2 -178 -1 -255 -6z"/>
            <path d="M2775 5609 c-413 -25 -500 -32 -601 -44 -111 -14 -218 -48 -256 -83
            -20 -17 -23 -53 -8 -82 15 -29 54 -32 112 -9 29 12 66 25 82 30 42 12 335 37
            601 49 127 7 242 16 258 22 30 12 43 44 33 83 -7 26 -42 46 -74 44 -9 -1 -75
            -5 -147 -10z"/>
            <path d="M2545 5275 c-333 -46 -365 -57 -380 -126 -14 -63 58 -106 106 -63 23
            20 281 62 440 71 86 4 140 2 217 -11 75 -13 106 -14 121 -6 46 24 51 88 10
            111 -69 36 -337 49 -514 24z"/>
            <path d="M1580 3929 c-53 -32 -86 -66 -132 -138 -23 -37 -59 -85 -80 -108 -21
            -24 -38 -53 -38 -65 0 -37 45 -94 92 -116 68 -33 236 -73 320 -77 70 -3 81 -6
            115 -34 55 -46 148 -88 228 -102 50 -10 82 -22 112 -45 58 -44 130 -67 203
            -62 52 3 62 0 87 -23 40 -37 56 -44 172 -74 57 -14 126 -37 153 -51 45 -23 56
            -24 133 -18 74 6 91 4 140 -16 42 -17 69 -21 111 -18 76 6 125 44 197 148 31
            47 80 106 107 132 28 25 50 54 50 63 0 9 18 33 40 52 46 41 51 72 19 104 -18
            18 -29 20 -67 15 -34 -5 -72 1 -156 25 -169 47 -282 70 -377 79 -74 6 -98 13
            -179 55 -90 45 -97 47 -160 43 -51 -4 -70 -1 -91 13 -39 27 -159 57 -299 75
            -175 22 -301 57 -497 138 -79 33 -154 35 -203 5z m149 -125 l39 -15 -24 -24
            c-31 -31 -30 -60 1 -91 22 -22 31 -25 67 -19 l41 7 -31 -33 c-18 -19 -32 -42
            -32 -51 0 -27 -77 -23 -196 8 -54 14 -99 26 -101 27 -5 6 109 167 133 186 32
            25 51 26 103 5z m325 -115 l43 -11 -4 -41 c-7 -55 11 -115 42 -149 21 -23 23
            -31 14 -51 -11 -24 -12 -24 -64 -12 -57 13 -129 51 -158 83 -21 23 -21 22 51
            72 44 32 53 60 32 101 -12 22 -12 22 44 8z m214 -35 c12 -4 22 -13 22 -22 0
            -16 -38 -62 -51 -62 -11 0 -19 43 -12 68 6 24 7 25 41 16z m185 -54 c-47 -38
            -50 -79 -10 -152 19 -35 19 -38 3 -71 -9 -19 -16 -40 -16 -45 0 -30 -83 -25
            -138 8 -32 20 -32 20 -17 51 9 17 40 56 68 87 47 51 62 76 80 134 4 11 13 15
            28 11 22 -6 22 -6 2 -23z m274 -45 c5 -17 -143 -75 -156 -62 -19 19 -12 27 42
            53 52 24 107 29 114 9z m151 -90 c7 -14 12 -45 12 -68 0 -39 -5 -47 -77 -115
            -72 -70 -79 -74 -113 -68 -101 17 -140 39 -140 81 0 31 17 41 159 101 81 34
            116 54 124 71 13 30 21 29 35 -2z m381 -50 c80 -19 147 -37 149 -40 3 -2 0
            -11 -7 -18 -9 -12 -19 -10 -59 10 -53 27 -95 25 -149 -8 -40 -25 -116 -108
            -145 -160 -18 -32 -27 -39 -52 -39 -17 0 -48 -4 -69 -9 -20 -5 -39 -5 -42 0
            -3 4 15 27 40 50 71 65 99 121 100 194 0 33 4 63 8 66 9 7 21 4 226 -46z m41
            -179 c0 -18 -66 -103 -90 -116 -27 -14 -50 -12 -50 4 0 22 100 126 121 126 10
            0 19 -6 19 -14z"/>
            <path d="M1170 3445 c-49 -36 -94 -105 -135 -208 -20 -51 -45 -106 -56 -123
            -32 -53 -26 -89 23 -124 64 -47 162 -92 211 -97 43 -5 46 -4 109 58 68 66 114
            144 163 275 31 82 31 91 1 135 -30 43 -72 65 -178 90 -100 24 -97 24 -138 -6z
            m163 -136 c23 -6 37 -15 34 -22 -43 -110 -78 -177 -113 -216 l-42 -46 -46 22
            c-25 13 -46 27 -46 33 0 17 61 164 87 208 27 47 24 47 126 21z"/>
            <path d="M1692 3263 c-48 -23 -87 -74 -175 -225 -83 -142 -86 -154 -47 -208
            11 -15 47 -42 82 -61 52 -27 74 -34 123 -34 76 0 109 19 174 100 87 108 152
            254 136 306 -11 33 -74 98 -116 120 -47 24 -131 25 -177 2z m137 -134 c24 -23
            23 -36 -12 -104 -26 -53 -96 -144 -119 -156 -13 -7 -30 -5 -63 10 l-45 20 55
            93 c75 129 100 158 134 158 15 0 37 -9 50 -21z"/>
            <path d="M2117 3106 c-52 -19 -63 -31 -83 -84 -10 -26 -41 -83 -71 -127 -69
            -105 -74 -131 -34 -178 41 -46 123 -87 176 -87 58 0 136 42 190 102 l45 50
            -15 -42 c-31 -89 -18 -112 90 -166 104 -50 163 -43 265 32 64 47 116 117 126
            170 3 19 17 40 35 53 39 29 39 71 2 101 -18 14 -35 19 -50 15 -13 -3 -53 4
            -89 15 -124 38 -220 19 -292 -58 -22 -23 -32 -30 -28 -17 4 11 10 42 13 68 5
            44 3 51 -27 82 -61 63 -181 96 -253 71z m136 -145 c19 -19 -11 -87 -62 -143
            -41 -44 -53 -52 -84 -52 -57 0 -65 16 -30 61 36 46 73 116 73 139 0 14 8 16
            46 11 25 -2 51 -10 57 -16z m390 -122 c20 -5 37 -14 37 -20 0 -36 -60 -103
            -121 -135 -25 -13 -33 -13 -64 0 -19 8 -35 21 -35 27 0 29 45 102 74 120 35
            22 56 23 109 8z"/>
            <path d="M733 2824 c-19 -19 -23 -31 -18 -51 5 -20 -6 -53 -41 -129 -34 -75
            -45 -109 -40 -128 3 -15 35 -55 71 -91 72 -73 102 -82 132 -40 10 13 41 50 70
            81 87 96 154 255 129 306 -22 44 -86 69 -187 73 -86 4 -92 2 -116 -21z m167
            -119 c0 -32 -105 -179 -120 -170 -5 3 9 44 33 90 34 69 46 85 64 85 13 0 23
            -2 23 -5z"/>
            <path d="M1235 2720 c-48 -19 -84 -83 -124 -218 -26 -87 -26 -107 1 -144 70
            -99 202 -116 274 -36 31 34 75 113 89 161 11 37 16 42 43 42 36 0 72 30 72 60
            0 38 -31 66 -80 72 -25 4 -56 15 -70 25 -29 21 -121 48 -160 47 -14 0 -34 -4
            -45 -9z m118 -174 c-7 -41 -54 -127 -75 -140 -14 -8 -22 -7 -35 6 -16 17 -16
            21 1 75 10 32 24 70 32 85 13 24 16 26 48 16 32 -10 34 -14 29 -42z"/>
            <path d="M1665 2601 c-52 -24 -82 -57 -145 -161 -28 -47 -60 -88 -71 -92 -26
            -10 -41 -51 -29 -82 10 -28 43 -49 68 -44 9 2 33 -6 52 -17 80 -49 156 -58
            220 -25 52 26 114 106 141 179 20 57 45 81 84 81 18 0 45 39 45 65 0 12 -11
            32 -25 45 -23 21 -29 22 -70 13 -29 -7 -48 -7 -51 -1 -12 19 -109 58 -144 58
            -19 0 -53 -9 -75 -19z m100 -123 c14 -6 25 -18 25 -27 0 -27 -46 -115 -74
            -140 -24 -22 -29 -23 -66 -12 -22 6 -40 17 -40 22 0 19 73 130 98 150 29 22
            25 22 57 7z"/>
            <path d="M2309 2521 c-17 -24 -26 -28 -40 -21 -32 18 -109 11 -151 -12 -44
            -25 -111 -118 -153 -213 -17 -40 -37 -67 -58 -80 -58 -37 -43 -105 25 -120 17
            -4 45 -16 63 -26 18 -11 56 -22 84 -26 129 -15 278 128 317 304 36 167 36 166
            16 193 -29 38 -75 39 -103 1z m-61 -157 c21 -14 21 -17 6 -52 -27 -65 -62
            -110 -109 -138 -34 -19 -51 -24 -66 -18 -18 9 -18 11 11 72 66 140 105 174
            158 136z"/>
            <path d="M530 2284 c-14 -9 -38 -34 -55 -57 -16 -23 -48 -65 -71 -94 -46 -56
            -69 -112 -59 -142 3 -10 28 -39 54 -64 51 -47 99 -65 146 -53 57 14 161 141
            187 229 9 29 19 42 40 49 49 17 65 80 28 113 -15 13 -31 17 -69 13 -30 -3 -58
            0 -72 8 -33 18 -99 17 -129 -2z m70 -155 c-25 -50 -76 -119 -87 -119 -28 0
            -20 23 27 82 27 34 50 66 50 72 0 6 4 6 11 -1 8 -8 8 -17 -1 -34z"/>
            <path d="M2262 2033 c-39 -19 -74 -67 -132 -183 l-43 -85 -9 35 c-22 83 -119
            134 -207 110 -60 -16 -104 -60 -156 -156 -31 -56 -56 -89 -75 -99 -31 -16 -45
            -44 -35 -74 7 -22 87 -91 134 -116 34 -17 112 -18 154 0 14 6 17 2 17 -20 0
            -33 79 -110 154 -151 65 -35 167 -44 230 -20 93 36 190 170 313 434 44 95 53
            124 49 155 -6 51 -35 81 -110 111 -33 13 -88 36 -121 50 -69 30 -116 32 -163
            9z m162 -154 c34 -16 66 -29 73 -29 27 0 20 -25 -47 -157 -122 -243 -171 -303
            -248 -303 -34 0 -91 26 -130 60 l-24 20 37 42 c20 24 74 113 120 198 46 85 91
            168 99 183 18 32 23 32 120 -14z m-480 -107 c2 -4 -12 -45 -32 -90 -34 -76
            -40 -84 -75 -94 -56 -17 -65 -1 -33 56 14 25 37 66 51 91 21 37 31 45 55 45
            16 0 31 -3 34 -8z"/>
            <path d="M1445 2016 c-44 -20 -59 -40 -108 -149 -34 -74 -52 -103 -76 -118
            -34 -21 -48 -57 -32 -87 18 -34 130 -82 190 -82 71 0 109 18 163 79 46 51 108
            178 108 221 0 39 -28 75 -83 108 -64 39 -119 48 -162 28z m85 -136 c16 -9 17
            -14 8 -43 -21 -61 -77 -127 -109 -127 -15 0 -11 14 24 90 22 50 45 90 50 90 4
            0 17 -5 27 -10z"/>
            </g>
        </svg>
    )
}

Bags.propTypes = {
    height: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
}

export default Bags;