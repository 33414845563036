import React from 'react';


const Funnel = ({ size, style, title }) => {
    return (
      <svg
        style={style}
        height={size}
        viewBox="-21 -20 656 656"
        width={size}
        xmlns="http://www.w3.org/2000/svg"
      >
          <title>{title}</title>
        <path d="m527.503906 245v-131.722656c0-5.527344-4.851562-9.527344-10.375-9.527344h-37.984375c-5.511719 0-10.390625 4-10.390625 9.527344v131.722656h-6.25v-74.097656c.015625-2.605469-1.03125-5.101563-2.890625-6.921875-1.859375-1.816407-4.378906-2.804688-6.980469-2.730469h-37.988281c-2.578125-.0625-5.070312.933594-6.890625 2.753906-1.820312 1.824219-2.816406 4.320313-2.75 6.898438v74.097656h-7.5v-104.667969c0-5.523437-3.851562-10.332031-9.375-10.332031h-37.984375c-5.613281.148438-10.101562 4.71875-10.140625 10.332031v104.667969h-13.75v-156c0-5.523438-4.476562-10-10-10-5.523437 0-10 4.476562-10 10v165.734375c-.085937 5.546875 4.308594 10.128906 9.847656 10.265625h229.074219c5.523438 0 10-4.476562 10-10s-4.476562-10-10-10zm-38.75-121.25h18.75v121.25h-18.75zm-63.75 57.5h17.5v63.75h-17.5zm-65-31.25h17.5v95h-17.5zm0 0" />
        <path d="m430.640625 0c-64.929687.0820312-125.039063 34.253906-158.328125 90h-262.308594c-3.636718.003906-6.988281 1.976562-8.75 5.15625-1.761718 3.183594-1.660156 7.070312.265625 10.15625l180.984375 288.5625v117.125c.011719 3.101562 1.449219 6.023438 3.90625 7.914062l121.695313 94c1.753906 1.351563 3.90625 2.085938 6.117187 2.085938 1.457032 0 2.886719-.351562 4.175782-1.023438 3.367187-1.710937 5.453124-5.203124 5.355468-8.976562v-211.125l27.28125-43.125c24.8125 11.863281 51.976563 18.007812 79.488282 17.976562 101.660156 0 184.421874-82.707031 184.421874-184.367187 0-101.65625-82.644531-184.359375-184.304687-184.359375zm-168.683594 110c-10.394531 23.445312-15.738281 48.8125-15.683593 74.460938 0 3.082031.078124 5.539062.230468 9.289062h-165.996094l-52.425781-83.75zm43.566407 275.679688c-1.070313 1.570312-1.6875 3.414062-1.769532 5.3125v193.648437l-101.25-78.554687v-115.09375c.003906-1.847657-.515625-3.65625-1.507812-5.21875l-107.949219-172.023438h155.546875c8.519531 52.796875 39.53125 99.300781 84.988281 127.464844zm125.121093-36.949219c-90.640625 0-164.371093-73.730469-164.371093-164.363281 0-90.636719 73.730468-164.367188 164.367187-164.367188 90.632813 0 164.363281 73.734375 164.363281 164.367188 0 90.628906-73.730468 164.363281-164.363281 164.363281zm0 0" />
      </svg>
    );
}


export default Funnel;